<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item>
				<el-input v-model="userName" placeholder="用户名称" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
				<el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column prop="nickname" header-align="center" align="center" label="用户昵称"></el-table-column>
			<el-table-column prop="phone" header-align="center" align="center" label="手机号"></el-table-column>
			<el-table-column prop="roleName" header-align="center" align="center" label="角色"></el-table-column>
			<el-table-column header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-popconfirm
						title="确认删除这个用户？"
						@confirm="deleteHandle(scope.row.id)">
						<el-button type="text" size="small" style="margin-left: 10px" slot="reference">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			userName: '',
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0
		};
	},
	created () {
		this.search();
	},
	methods: {
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			this.$root.request('userList', {
				keyword: this.userName,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows;
				}
			});
		},
		addOrUpdateHandle (id) {
			this.$root.go('/mnt/addOrUpdateUser', { id });
		},
		deleteHandle (id) {
			this.$root.request('userDelete', {
				id
			}).then((data) => {
				if (data) {
					this.$root.elMsg('操作成功');
					this.search();
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		}
	}
};
</script>